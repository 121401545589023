<template>
  <div>
    <div class="view-section">
      <TopNav v-if="!isLoginRoute" />
      <Sidebar v-if="!isLoginRoute" />
    </div>

    <router-view />
  </div>
</template>
<script>
import { mapState } from "vuex";
import TopNav from "./components/TopNav.vue";
import Sidebar from "./components/Sidebar.vue";
import DefaultLoading from "./components/DefaultLoading.vue";

export default {
  components: {
    TopNav,
    Sidebar,
    DefaultLoading,
  },
  data() {
    return {
      isLoginRoute: true,
    };
  },

  mounted() {
    // console.log(loader);
  },

  computed: {
    ...mapState(["loader"]),
  },
  watch: {
    $route(to, from) {
      this.isLoginRoute = to.path === "/" && to.name === "login";
    },
  },
};
</script>
<style></style>
