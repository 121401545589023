<template>
  <div class="container-fluid placeholder-glow bg-white" style="z-index: 1080; position: absolute">
    <div class="row" style="height: 9vh">
      <div class="col-12">
        <div
          class="row justify-content-between align-items-center border-bottom"
          style="padding-top: 10px; padding-bottom: 10px"
        >
          <div class="col-lg-2 px-3">
            <img src="../assets/image/Someekoron.svg" class="booklogo" alt="" srcset="" />
          </div>
          <div class="col-lg-2">
            <p class="rounded rounded-pill w-100 placeholder bg-light-2 py-3 m-0" style=""></p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="height: 90vh">
      <div class="col-lg-2 px-3">
        <div class="d-flex flex-column justify-content-between align-items-between h-100">
          <div>
            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>

            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>

            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>

            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>

            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>

            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>

            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>

            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>

            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>

            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>
            
            <p class="rounded rounded-2 w-100 placeholder bg-light-2 py-3 mb-2" style=""></p>
          </div>

          <div class="text-center ">
            <small class="mb-0 fw-bold fs-11 text-muted">Someekoron V1.1.0</small>
            <div class="d-flex justify-content-center align-items-center">
              <p class="mb-0 fs-11">Powered by</p>
              <img src="../assets//mpair_logo.png" class="mx-2" alt="" srcset="" style="height: 15px" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-10">
        <div class="row">
          <div class="col-lg-3">
            <div class="card placeholder border-0 bg-light-2 w-100" style="height: 18vh"></div>
          </div>

          <div class="col-lg-3">
            <div class="card placeholder border-0 bg-light-2 w-100" style="height: 18vh"></div>
          </div>

          <div class="col-lg-3">
            <div class="card placeholder border-0 bg-light-2 w-100" style="height: 18vh"></div>
          </div>

          <div class="col-lg-3">
            <div class="card placeholder border-0 bg-light-2 w-100" style="height: 18vh"></div>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-lg-8">
            <div class="card placeholder border-0 bg-light-2 w-100" style="height: 60vh"></div>
          </div>
          <div class="col-lg-4">
            <div class="card placeholder border-0 bg-light-2 w-100" style="height: 60vh"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import TopNav from "./TopNav.vue";
export default {
  name: "DefaultLoading",
  data() {
    return {};
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: { TopNav },
};
</script>
<style scoped>
.bg-light-2 {
  background: rgb(193, 214, 179);
  background: linear-gradient(
    33deg,
    rgba(193, 214, 179, 1) 0%,
    rgba(203, 213, 187, 1) 30%,
    rgba(201, 204, 219, 1) 68%,
    rgba(211, 225, 236, 1) 100%
  );
}
</style>
